<script>
export default {
  created() {
    this.$store
      .dispatch('auth/logout')
      .then(() => {
        console.log('logout');
        this.$router.push({ name: 'home' });
        window.location.reload();
      });
  },
};
</script>

<template>
  <b-loading active />
</template>
